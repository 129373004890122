<!--
   /**
      * about.vue
      * @module {src/modules/public}
      * @desc 前端关于我们
      * @author shiqiu
      * @date 2021-01-20
      */
-->
<template>
  <div>
    <div v-for="(indexPro,proKey) in proList" :key="proKey">
    <el-row type="flex" justify="center" style="margin-top: 5vw;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10">
                      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="margin_b_20">
                        <img :src="indexPro.introduce_img" class="indexPro">
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="proBg">
                        <el-row :gutter="10" class="margin_t_20">
                          <el-col :span="24">
                            <p class="bigTitle">{{indexPro.introduce_zh}}</p>
                            <p class="lineTitle"></p>
                            <p class="enTitle">{{indexPro.introduce_en}}</p>
                          </el-col>
                        </el-row>
                        <el-row :gutter="10" style="margin-top: 20px; margin-bottom: 20px" type="flex" justify="center">
                          <el-col :xs="23" :sm="23" :md="20" :lg="20" :xl="20">
                            <p class="introduce">{{indexPro.introduce}}</p>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 10px;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10">
                      <el-col :span="24">
                        <p class="bigTitle">体系架构</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">The architecture</p>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10"  type="flex" justify="center">
                      <el-col :span="20" class="margin_b_40 margin_t_40">
                        <p style="margin-bottom: 40px; font-size: 18px">系统由三⼤部分组成：物联⽹⽹关、 物联⽹PAAS平台、可定制化的各类型⾏业应⽤</p>
                        <p style="text-align: center">
                          <img :src="indexPro.system_img" style="width: 80%">
                          <img :src="indexPro.system_img1" style="width: 80%">
                          <img :src="indexPro.system_img2" style="width: 80%">
                        </p>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Login',
  components: {},
  data () {
    return {
      proList:[
        {
          introduce_img:'/static/images/iot.png',
          introduce_zh:'物联网PAAS系统',
          introduce_en:'INTERNET OF THINGS PAAS SYSTEM',
          introduce:'物联⽹ (IoT) 是由物理对象（所谓的 “物”）组成的⽹络，这些物理对象嵌⼊了传感器、软件和其他技术，以便可以通过互联⽹与其他设备和系统建⽴连接并交换数据。物联⽹设备种类繁多，既有普通家庭⽤品，也有复杂⼯业⽤具。在过去⼏年中，物联⽹已成为21世纪⾮常重要的技术之⼀。如今，我们可以通过嵌⼊式设备将各种⽇常物品（厨房⽤具、汽⻋、恒温器和婴⼉监视器）连接到互联⽹，从⽽实现⼈员、流程与物品之间的⽆缝通信',
          system_img:'/static/images/iot-new-01.png',
          system_img1:'/static/images/iot-new-02.png',
          system_img2:'/static/images/iot-new-03.png'
        }
      ]
    }
  },
  mounted () {

  },
  watch: {

  },
  methods: {

  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
body {
  background: #fff !important;
}
</style>
